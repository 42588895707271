import { parseISO, isValid } from "date-fns"
import { format, utcToZonedTime } from "date-fns-tz"
import { captureMessage } from "@sentry/nextjs"

/**
 * @param {String} label
 * @returns the image path for the associated icon
 */
export const getLabelIcon = (label) => {
  const labelMap = {
    Blog: "resource-blog",
    "Business/Individual": "icon-business",
    "Case Study": "resource-case-study",
    "Culture/Workplace": "icon-culture",
    Customer: "icon-customer",
    Demo: "resource-video",
    Event: "resource-event",
    Guide: "resource-guide",
    Infographic: "resource-research-report",
    Product: "icon-product",
    "Product Brief": "resource-brief",
    Podcast: "resource-webinar",
    "Research and Reports": "resource-research-report",
    "Solution Brief": "resource-brief",
    Video: "resource-video",
    Webinar: "resource-webinar"
  }

  const defaultIcon = "resource-page"

  return labelMap[label] || defaultIcon
}
/**
 * @param {String} label
 * @returns CTA wording for card based on label
 */
export const getCTAFromLabel = (label) => {
  const ctaMap = {
    Blog: "Read blog",
    "Case Study": "Read case study",
    Event: "Details",
    Demo: "Watch demo",
    Guide: "Read more",
    Infographic: "Read more",
    Page: "Read more",
    Platform: "Read more",
    "Product Brief": "Read brief",
    Podcast: "Listen now",
    "Research and Reports": "Read more",
    "Solution Brief": "Read brief",
    Video: "Watch now",
    Webinar: "Register"
  }

  return ctaMap[label] || "Read more"
}

/**
 * @param {String} resourceType
 * @returns The 6sense activity type mapped to a given resourceType
 */
export const getActivityType = (resourceType) => {
  const activityTypeMap = {
    "Case Study": "case_studies",
    Demo: "video",
    Guide: "white_paper",
    Infographic: "data_sheet",
    "Product Brief": "data_sheet",
    Podcast: "webinar",
    "Research and Reports": "white_paper",
    Video: "video",
    "Solution Brief": "data_sheet",
    Webinar: "webinar"
  }
  return activityTypeMap[resourceType] || null
}

export const getDateTime = (startDate, endDate, showTime) => {
  let userTimeZone, localStartDateTime, localEndDateTime, formattedDate
  if (!startDate || isValid(startDate)) return null

  try {
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (userTimeZone === "Etc/Unknown") userTimeZone = undefined
    localStartDateTime = utcToZonedTime(parseISO(startDate), userTimeZone)

    if (endDate && !isValid(endDate)) {
      if (startDate.toString() === endDate?.toString()) return format(localStartDateTime, "MMM d")

      localEndDateTime = utcToZonedTime(parseISO(endDate), userTimeZone)
      return `${format(localStartDateTime, "MMM d")} - ${format(localEndDateTime, "MMM d")}`
    }
    formattedDate = showTime
      ? format(localStartDateTime, "MMM d HH:mm a zzz")
      : format(localStartDateTime, "MMM d")
    return formattedDate
  } catch (ex) {
    console.error(ex)
    captureMessage(`Timezone error: ${ex}`, {
      extra: {
        startDate,
        endDate,
        formattedDate
      },
      level: "error"
    })
  }

  return null
}
