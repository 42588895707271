export default {
  default: {
    primaryCta: {
      label: "Contact Sales",
      url: "/contact-sales"
    },
    secondaryCta: {
      label: "Demo",
      url: "/resources/demo/request"
    },
    login: {
      buttonLabel: "Login",
      buttonUrl: "https://login.amperity.com/"
    },
    hideNavLeft: false,
    hideNavRight: false,
    menu: [
      {
        label: "Product",
        items: [
          {
            links: [
              {
                url: "/platform/customer-data-cloud",
                image: "/img/Nav_Featured_Platform.png",
                imageDescription: "Screenshot of the Stitch feature on Amperity.",
                label: "Amperity Customer Data Cloud",
                description:
                  "Organize and democratize data across your infrastructure with AI solutions."
              }
            ]
          },
          {
            heading: "Platform",
            links: [
              {
                icon: "/icons/ic_nav_Reverse_ETL.svg",
                iconDescription: "Reverse ETL",
                url: "/platform/reverse-etl",
                label: "Reverse ETL",
                description: "End-to-end observability and full sandboxes"
              },
              {
                icon: "/icons/ic_nav_Identity_Resolution.svg",
                iconDescription: "Amperity stitch",
                url: "/platform/identity-resolution",
                label: "Identity Resolution",
                description: "ML-driven customer data profiles"
              },
              {
                icon: "/icons/ic_nav_Customer_360.svg",
                iconDescription: "Database",
                url: "/platform/customer-360",
                label: "Customer 360",
                description: "Customizable data models and AI predictions"
              },
              {
                icon: "/icons/ic_nav_Audience_Hub.svg",
                iconDescription: "Audience Hub",
                url: "/platform/audience-hub",
                label: "Audience Hub",
                description: "Automated data delivery to any endpoint"
              },
              {
                icon: "/icons/ic_nav_Amp_AI.svg",
                iconDescription: "AmpAI",
                url: "/platform/ai",
                label: "AmpAI",
                description: "Data exploration and productivity AI",
                isAiLink: true
              }
            ]
          },
          {
            heading: "Capabilities",
            links: [
              {
                url: "/capabilities/data-warehouse",
                label: "Data Sharing"
              },
              {
                url: "/capabilities/real-time-personalization",
                label: "Real-Time"
              },
              {
                url: "/integrations",
                label: "Integrations"
              },
              {
                url: "/capabilities/security-and-privacy",
                label: "Security & Privacy"
              }
            ],
            secondaryHeading: "Patents",
            secondaryLinks: [
              {
                url: "/patents",
                label: "Proprietary Technology"
              }
            ]
          }
        ]
      },
      {
        label: "Solutions",
        items: [
          {
            heading: "By Use Case",
            links: [
              {
                url: "/solutions/use-cases/audience-onboarding",
                label: "Audience Onboarding"
              },
              {
                url: "/solutions/use-cases/omnichannel-personalization",
                label: "Personalization"
              },
              {
                url: "/solutions/use-cases/elevating-loyalty",
                label: "Loyalty"
              },
              {
                url: "/solutions/use-cases/customer-care",
                label: "Customer Care"
              }
            ]
          },
          {
            heading: "By Function",
            links: [
              {
                url: "/solutions/departments/marketing",
                label: "Marketing"
              },
              {
                url: "/solutions/departments/it",
                label: "Developers"
              },
              {
                url: "/solutions/departments/analytics",
                label: "Analytics"
              }
            ]
          },
          {
            heading: "By Industry",
            links: [
              {
                url: "/solutions/industries/retail",
                label: "Retail"
              },
              {
                url: "/solutions/industries/travel-hospitality",
                label: "Travel & Hospitality"
              },
              {
                url: "/solutions/industries/restaurants",
                label: "Restaurants"
              },
              {
                url: "/solutions/industries/financial-services",
                label: "Financial Services"
              }
            ]
          }
        ]
      },
      {
        label: "Resources",
        items: [
          {
            links: [
              {
                image: "/img/Nav_Featured_Resources.jpg",
                imageDescription: "Guide on teal background",
                url: "/resources",
                label: "All Resources",
                description: "Case studies, downloadable guides, videos, and more."
              }
            ]
          },
          {
            links: [
              {
                label: "Demo Center",
                url: "/resources/demo"
              },
              {
                label: "RFP Guide",
                url: "/resources/rfp-guide"
              },
              {
                label: "Blog",
                url: "/blog"
              },

              {
                url: "/events",
                label: "Events"
              },
              {
                url: "/resources/webinars",
                label: "Webinars"
              }
            ]
          },
          {
            links: [
              {
                url: "https://docs.amperity.com",
                label: "Documentation"
              },
              {
                url: "https://learn.amperity.com",
                label: "Learning Lab"
              },
              {
                url: "https://help.amperity.com/hc/en-us/",
                label: "Help Center"
              }
            ]
          }
        ]
      },
      {
        label: "Customers",
        url: "/customers"
      },
      {
        label: "Pricing",
        url: "/pricing"
      },
      {
        label: "Partners",
        items: [
          {
            heading: "Become a partner",
            links: [
              {
                url: "/partners",
                label: "Partner Ecosystem"
              },
              {
                url: "/partners/cloud-partners",
                label: "Cloud Partners"
              },
              {
                url: "/partners/technology-partners",
                label: "Technology Partners"
              },
              {
                url: "/partners/solutions-partners",
                label: "Solutions Partners"
              }
            ]
          },
          {
            heading: "Featured Partners",
            links: [
              {
                url: "/partners/databricks ",
                label: "Databricks"
              },
              {
                url: "/partners/snowflake ",
                label: "Snowflake"
              },
              {
                url: "/partners/aws",
                label: "Amazon Web Services"
              },
              {
                url: "/partners/microsoft",
                label: "Microsoft Azure"
              }
            ]
          },
          {
            links: [
              {
                image: "/img/Nav_Featured_Partner_LL.svg",
                imageDescription: "Logo displaying AMP.",
                url: "https://learn.amperity.com/page/partner-learning-homepage",
                label: "Partner Learning Lab",
                description:
                  "Log in to gain access to resources, tools, and information to help you grow your business."
              }
            ]
          }
        ]
      }
    ]
  }
}
